import { cloneDeep, merge } from "lodash";
import {
  onlyNotMandatoryAttributes,
  onlyNotMandatoryCategory,
} from "modules/UserSegmentation/utils/segmentDataTransformation";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AttributesCategory,
  Segment,
  SegmentsAttribute,
  SegmentsAttributesResponse,
  SegmentsAttributeValue,
  TargetSystem,
} from "types/services/segments.definitions";
import { REGION_NAME, useSegmentAttributes, useSegmentsSize } from "./query";

/**
 *
 * Calls all regions and merges attributes
 */
export function useMergedSegmentAttributes() {
  const { data, isLoading, isError, error } = useSegmentAttributes();
  const mergeAttributes = useCallback(() => {
    if (!data) {
      return null;
    }
    function onlyUnique(value: SegmentsAttributeValue, index: number, self: Array<SegmentsAttributeValue>) {
      if (typeof value === "string") {
        return self.indexOf(value) === index;
      } else {
        return self.findIndex(attr => typeof attr === "object" && attr.label === value.label) === index;
      }
    }
    const resultAttributes: SegmentsAttribute[] = [];
    const attrsMap = new Map();
    for (const regionAttrs of data ?? []) {
      for (const attr of regionAttrs?.attributes ?? []) {
        if (attrsMap.has(attr.name)) {
          // append
          attrsMap.get(attr.name).values = [...(attrsMap.get(attr.name).values ?? []), ...(attr.values ?? [])].filter(
            onlyUnique
          );
        } else {
          // create new key
          const resultAttr = cloneDeep(attr);
          attrsMap.set(attr.name, resultAttr);
          resultAttributes.push(resultAttr);
        }
      }
    }

    return resultAttributes;
  }, [data]);

  const mergedAttributes = useMemo(mergeAttributes, [data]);
  // categories for all regions will be the same, no need to merge
  const categories: SegmentsAttributesResponse["categories"] = useMemo(() => (data ?? [])[0]?.categories ?? [], [data]);
  return {
    isLoading,
    attributes: mergedAttributes,
    categories,
    isError,
    error,
  };
}

const onlyTargetSystem = <T extends { allowedSystem: TargetSystem[] }>(targetSystem: TargetSystem) => {
  return (x: T) => x.allowedSystem.includes(targetSystem);
};

export const useAttributesUtils = (
  attributes: SegmentsAttribute[],
  categories: SegmentsAttributesResponse["categories"]
) => {
  const { t } = useTranslation();
  const byFieldNameDict = useMemo(() => {
    const dict: Record<string, SegmentsAttribute> = {};
    for (const attr of attributes) {
      dict[attr.name] = attr;
    }
    return dict;
  }, [attributes]);
  const byCategoryDict = useMemo(() => {
    const dict: Record<string, SegmentsAttribute[]> = {};
    for (const attr of attributes) {
      dict[attr.category] = [...(dict[attr.category] ?? []), attr];
    }
    return dict;
  }, [attributes]);

  const getByCategory = (category: string, targetSystem: TargetSystem) => {
    return byCategoryDict[category].filter(onlyTargetSystem(targetSystem)).filter(onlyNotMandatoryAttributes);
  };

  return {
    getByFieldName: (field: string) => {
      return byFieldNameDict[field];
    },
    getByCategory,
    getOptionsByCategory: (category: AttributesCategory, targetSystem: TargetSystem) => {
      if (category === "all") {
        return attributes
          .filter(onlyTargetSystem(targetSystem))
          .filter(onlyNotMandatoryAttributes)
          .map(attr => ({ label: t(`attributes.${attr.name}`), value: attr.name }));
      }
      return getByCategory(category, targetSystem).map(attr => ({
        label: t(`attributes.${attr.name}`),
        value: attr.name,
      }));
    },
    getCategories: (targetSystem: TargetSystem) => {
      return categories
        .filter(onlyTargetSystem(targetSystem))
        .filter(onlyNotMandatoryCategory)
        .map(cat => cat.name);
    },
  };
};
/**
 *
 * Calls all regions for sizes of segments
 */
export type SegmentSizeForRegion = {
  name: typeof REGION_NAME[keyof typeof REGION_NAME];
  sizeLabel: string;
  size: number;
};
export function useMergedSegmentsSize(segmentsIds: Segment["id"][]) {
  const { data, isLoading } = useSegmentsSize(segmentsIds);
  const segmentSizePerRegion = useCallback(
    (segmentId: Segment["id"]): SegmentSizeForRegion[] | undefined => {
      return data?.map(regionData => {
        const size = regionData.counts[segmentId];
        return {
          name: REGION_NAME[regionData.region],
          sizeLabel: typeof size == "number" ? size.toString() : "(?)",
          size: typeof size == "number" ? size : 0,
        };
      });
    },
    [data]
  );

  const sumSizeForSegment = useCallback(
    (segment_id: Segment["id"]) => {
      let sum: number = 0;
      let isValid = true;
      for (const regionData of data ?? []) {
        const size = regionData.counts[segment_id];
        sum = sum + (typeof size == "number" ? size : 0);
        isValid = isValid && regionData.counts[segment_id] !== null;
      }
      return {
        isValid,
        sum,
      };
    },
    [data]
  );

  return {
    segmentSizePerRegion,
    sumSizeForSegment,
    isLoading,
  };
}
