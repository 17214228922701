export enum Operation {
  And = "AND", // POTENTIALLY SHOULD BE ALSO REMOVED
  // Or = "OR", DEPRECATED - SHOULD BE REMOVED FROM BE
  Equal = "=",
  GreaterOrEqual = ">=",
  LessOrEqual = "<=",
  GreaterThan = ">",
  LessThan = "<",
  Between = "between", // between could be composed from <= AND >=, so other operators could make it, it should not be an operator
}

export type FieldCondition = {
  operation: Exclude<Operation, Operation.And>;
  field: string;
  value: string[] | number[] | [string, number];
};
// this is reality - we have AND on top or this is single condition
// this is because our AND operator is not monoid (cannot work with single argument)
export type SegmentQuery =
  | {
      operation: Operation.And;
      items: FieldCondition[];
    }
  | FieldCondition;

type FormConditionBase = {
  option: { label: string; value: string };
  category: AttributesCategory;
};

export type DateFormCondition = FormConditionBase & {
  inputType: "date";
  subOption: string[];
  values: null;
  operation: Operation.Between | Operation.GreaterThan | Operation.LessThan;
};

export type MultiSelectFormCondition = FormConditionBase & {
  inputType: "multi_select";
  subOption: string[];
  values: SegmentsLabeledAttr[] | string[] | null;
};

export type SingleSelectFormCondition = FormConditionBase & {
  inputType: "single_select_key_value";
  subOption: string[];
  values: SegmentsLabeledAttr[] | string[] | null;
};

export type NumberFormCondition = FormConditionBase & {
  inputType: "number";
  subOption: number[];
  values: null;
  operation: Operation.Between | Operation.GreaterThan | Operation.LessThan;
};

export type MultiSelectKeyValueCondition = FormConditionBase & {
  inputType: "multi_select_key_value";
  subOption: [string, number];
  values: SegmentsLabeledAttr[];
};

export type AttributesCategory = "all" | (string & {}); // & {} is a trick to autocomplete 'all'

// this represents not chosen option, default state
export type NoCondition = FormConditionBase & {
  option: { label: "Select..."; value: "Select..." };
  inputType: null;
  subOption: [];
  values: null;
};
export const makeNoCondition = (category: AttributesCategory): NoCondition => ({
  option: { label: "Select...", value: "Select..." },
  inputType: null,
  subOption: [],
  values: null,
  category,
});

export type AllowForPickingOperatorConditions = DateFormCondition | NumberFormCondition;

export type FormCondition =
  | DateFormCondition
  | SingleSelectFormCondition
  | MultiSelectFormCondition
  | NumberFormCondition
  | MultiSelectKeyValueCondition
  | NoCondition;

export type AvailableFormCondition = Exclude<FormCondition, NoCondition>;

export const isNoCondition = (condition: FormCondition): condition is NoCondition => condition.inputType === null;

export type Segment = {
  description: string;
  id: string;
  last_update: number;
  name: string;
  owner: string;
  query: SegmentQuery;
  regions: string[];
  target_system: TargetSystem;
  register_country: string;
  last_login_country: string[];
};

export type SegmentResponse = { segment: Segment; access: ("read" | "write")[] };
export type SegmentsResponse = Segment[];

export enum SegmentExceptions {
  DuplicateSegmentNameException = "DuplicateSegmentNameException",
  InternalErrorWhenSendingRequestToUserProfile = "InternalErrorWhenSendingRequestToUserProfile",
  NotValidQueryException = "NotValidQueryException",
}

export interface SegmentCustomError {
  data: {
    detail: {
      exception: SegmentExceptions;
      exception_message: string;
    };
  };
  status: number;
}

export type UiSubtype = null | "feature" | "banner" | "page";

export type SegmentsLabeledAttr = {
  label: string;
  value: string;
};

export type SegmentsAttributeValue = string | SegmentsLabeledAttr;

export type TargetSystem = "CMS" | "IMS";

export type SegmentsAttribute = {
  name: string;
  description: string;
  sortedValues: boolean;
  category: string;
  uiSubtype: UiSubtype;
  allowedSystem: TargetSystem[];
} & (
  | {
      values: string[] | null;
      inputType: "date";
    }
  | {
      values: SegmentsLabeledAttr[] | string[] | null;
      inputType: "single_select_key_value" | "multi_select";
    }
  | {
      values: null;
      inputType: "number";
    }
  | {
      values: SegmentsLabeledAttr[];
      inputType: "multi_select_key_value";
    }
);

export type SegmentsAttributesResponse = {
  attributes: SegmentsAttribute[];
  categories: {
    name: string;
    allowedSystem: TargetSystem[];
  }[];
};

export enum SegmentsAttributeInputType {
  SingleSelectKeyValue = "single_select_key_value",
  MultiSelect = "multi_select",
  MultiSelectValue = "multi_select_key_value",
  Date = "date",
  Number = "number",
}

export type SegmentsCountsItem = number | { exception: string };

export type SegmentsCounts = {
  [key: string]: SegmentsCountsItem;
};
