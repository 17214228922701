import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import global from "./global";
import fonts from "./fonts";
import { table, button, select, menu } from "./components";

export const theme = extendTheme({
  colors,
  styles: { global },
  fonts,
  components: { Table: table, Button: button, Select: select, Menu: menu },
});
