import { useCallback, useEffect, useMemo, useState } from "react";
import { SEGMENTS_LIST_ITEMS_PER_PAGE } from "consts";
import { useTrack } from "./useTrack";

const getCount = (values: unknown[]) => Math.ceil(values.length / SEGMENTS_LIST_ITEMS_PER_PAGE);
const getOffset = (page: number) => (page > 0 ? page * SEGMENTS_LIST_ITEMS_PER_PAGE : 0);
const getItems = <T>(offset: number, values: T[]) => values.slice(offset, offset + SEGMENTS_LIST_ITEMS_PER_PAGE);

export function usePagination<T>(values: T[]) {
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = useMemo(() => getCount(values), [values]);
  const offset = useMemo(() => getOffset(currentPage), [values, currentPage]);
  const currentItems = useMemo(() => getItems(offset, values), [offset, values]);

  const { track } = useTrack();

  const handleNextPageClick = useCallback(() => {
    if (values.length == 0 || currentPage == pageCount - 1) {
      return;
    }
    setCurrentPage(currentPage + 1);
    track("pagination_next_page", { page: currentPage + 1 });
  }, [currentPage, values]);

  const handlePrevPageClick = useCallback(() => {
    if (values.length == 0 || currentPage == 0) {
      return;
    }
    setCurrentPage(currentPage - 1);
    track("pagination_next_page", { page: currentPage - 1 });
  }, [currentPage, values]);

  useEffect(() => {
    if (pageCount <= currentPage && pageCount != 0) {
      // if our current page is out of possible pages we go back
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, values]);

  return {
    handleNextPageClick,
    handlePrevPageClick,
    currentItems,
    itemOffset: offset,
    pageCount,
    currentPage,
  };
}
