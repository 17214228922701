import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FC } from "react";

type NumberInputProps = {
  value?: number;
  onChange: (value: number) => void;
  isDisabled?: boolean;
  width?: string;
  min?: number;
  max?: number;
};

export const NumberInput: FC<NumberInputProps> = ({
  value,
  onChange,
  isDisabled = false,
  width = "100px",
  min = -Infinity,
  max = +Infinity,
}) => (
  <ChakraNumberInput
    isDisabled={isDisabled}
    value={value}
    min={min}
    max={max}
    onChange={value => onChange(+value)}
    w={width}
    bg="white"
  >
    <NumberInputField _focus={{}} />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </ChakraNumberInput>
);
