import { useNavigation } from "hooks/useNavigation";
import { useNotification } from "hooks/useNotification";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "redux/features/authSlice";
import { getAuth } from "store";
import { refreshToken, useLogoutMutation, useRefreshTokenMutation } from "./auth";

export const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
});

export const useAuthFetch = () => {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const [logout, { isSuccess: logoutSuccess }] = useLogoutMutation();
  const { toLogin } = useNavigation();
  const { showError } = useNotification();

  const handleLogout = useCallback(() => {
    logout();
    showError({ body: "Your session is not active. Please log in again.", id: "logout" });
  }, [logout, showError]);

  useEffect(() => {
    if (logoutSuccess) {
      toLogin();
    }
  }, [logoutSuccess]);
  return {
    fetch: async (url: Parameters<typeof fetch>[0], options: Parameters<typeof fetch>[1]) => {
      const data = await fetch(url, {
        ...options,
        headers: makeHeaders(auth.token as string),
      });

      switch (data.status) {
        case 200:
          return data.json();
        case 400: {
          // TODO - backend should have one structure of error
          const resp: { message?: string; detail?: { exception: string; exception_message: string } } =
            await data.json();

          if (resp.message) {
            if (resp.message.includes("Token error")) {
              // TODO: this is workaround, we need to make different http code in the server for this
              // token error with 400 means token is not there or is wrong, so we need to logout
              handleLogout();
            }
            throw new Error(resp.message, {
              cause: { type: "http", status: data.status, msg: resp.message },
            });
          }
          if (resp.detail) {
            throw new Error(resp.detail?.exception ?? resp.message, {
              cause: { type: "http", status: data.status, msg: resp.detail.exception_message },
            });
          }
          return;
        }
        case 401:
          // get the new token and call again the end point
          try {
            const token = await refreshToken();
            const second_data = await fetch(url, {
              ...options,
              headers: makeHeaders(token.access_token as string),
            });
            dispatch(setCredentials({ token: token.access_token, expireTime: token.expire_time }));
            return second_data.json();
          } catch (e) {
            // refreshing token did not work we need to logout the user
            handleLogout();
            throw new Error("Unauthorized", { cause: { type: "http", status: 401 } });
          }
        case 500: {
          const resp = await data.json();
          throw new Error(resp.detail.exception_message);
        }
        default: {
          const resp = await data.json();
          throw new Error(resp?.message ?? "Error");
        }
      }
    },
  };
};
