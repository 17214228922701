import { Button, Menu, MenuButton, MenuList, MenuDivider, MenuItem, Spinner } from "@chakra-ui/react";
import { getLabel } from "modules/UserSegmentation/utils/getLabel";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BsPlusLg } from "react-icons/bs";
import { AttributesCategory } from "types/services/segments.definitions";

type CategoriesButtonProps = {
  categories: string[];
  onClick: (category: AttributesCategory) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const CategoriesButton: FC<CategoriesButtonProps> = ({
  categories,
  isDisabled = false,
  isLoading = false,
  onClick,
}) => {
  const { t } = useTranslation();

  const categoriesUi = useMemo(() => {
    const children: JSX.Element[] = [];
    let index = 0;

    categories.forEach(category => {
      children.push(
        <MenuItem
          key={index}
          fontSize="14px"
          fontWeight="500"
          _hover={{
            color: "brand.red",
          }}
          _focus={{}}
          onClick={() => onClick(category)}
        >
          {t(`categories.${category}`, getLabel(category))}
        </MenuItem>
      );
      index++;
    });

    if (categories.length) {
      children.push(<MenuDivider key={index} />);
      index++;
    }

    children.push(
      <MenuItem
        key={index}
        fontSize="14px"
        fontWeight="700"
        onClick={() => onClick("all")}
        _hover={{
          color: "brand.red",
        }}
        _focus={{}}
      >
        {t("components.categoriesButton.buttonText")}
      </MenuItem>
    );

    return children;
  }, [categories, onClick, t]);

  return (
    <Menu>
      <MenuButton
        as={Button}
        isDisabled={isDisabled || isLoading}
        variant="clear"
        mr="-px"
        leftIcon={isLoading ? <Spinner /> : <BsPlusLg />}
        _hover={{
          color: "brand.red",
        }}
        _focus={{}}
      >
        Add another...
      </MenuButton>
      <MenuList>{categoriesUi}</MenuList>
    </Menu>
  );
};
