import {
  Box,
  Text,
  Icon,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Button,
  FormErrorMessage,
  Flex,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FC, useState } from "react";
import { Form, Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { AiOutlineArrowLeft, AiOutlineSave } from "react-icons/ai";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { DatePicker } from "components/DatePicker";
import {
  AttributesCategory,
  FormCondition,
  isNoCondition,
  makeNoCondition,
  Operation,
  SegmentsAttribute,
  SegmentsAttributesResponse,
  TargetSystem,
} from "types/services/segments.definitions";
import { FilterNumber } from "components/FilterNumber";
import { Select as SelectComponent } from "components/Select";
import {
  changeOptionInFormCondition,
  changeSubOptionInFormCondition,
  defaultSubOptionForAttr,
} from "../utils/segmentDataTransformation";
import { getLabel } from "../utils/getLabel";
import { CategoriesButton } from "components/CategoriesButton";
import { MultiSelectValue } from "components/MultiSelectValue";
import { useTranslation } from "react-i18next";
import { TARGET_SYSTEM, LAST_LOGIN_COUNTRY, REGISTER_COUNTRY } from "../constants";
import { useNavigation } from "hooks/useNavigation";
import { useAttributesUtils } from "api/segmentsAllRegions";

type ConditionSubOptionInputProps = {
  condition: FormCondition;
  attr: SegmentsAttribute;
  arrayHelpers: any;
  index: number;
  disabled: boolean;
};
const ConditionSubOptionInput: FC<ConditionSubOptionInputProps> = ({
  condition,
  attr,
  arrayHelpers,
  index,
  disabled,
}: {
  condition: FormCondition;
  attr: SegmentsAttribute;
  arrayHelpers: any;
  index: number;
  disabled: boolean;
}) => {
  switch (condition.inputType) {
    case "date": {
      const value = condition.subOption;
      return (
        <DatePicker
          isDisabled={disabled}
          initValue={value}
          operation={condition.operation ?? Operation.GreaterThan}
          onChange={(subOption, operation) => {
            arrayHelpers.replace(index, changeSubOptionInFormCondition(condition, subOption, operation));
          }}
        />
      );
    }
    case "number":
      return (
        <FilterNumber
          isDisabled={disabled}
          initValue={condition.subOption}
          operation={condition.operation ?? Operation.GreaterThan}
          onChange={(subOption, operation) => {
            arrayHelpers.replace(index, changeSubOptionInFormCondition(condition, subOption, operation));
          }}
        />
      );
    case "multi_select_key_value":
      return (
        <MultiSelectValue
          name={condition.option.value}
          type={attr.uiSubtype}
          isDisabled={disabled}
          value={condition.subOption}
          onChange={subOption => {
            arrayHelpers.replace(index, changeSubOptionInFormCondition(condition, subOption));
          }}
          options={condition.values}
        />
      );
    case "multi_select":
    default:
      return (
        <SelectComponent
          isMulti
          isDisabled={disabled}
          name={condition.option.value}
          value={condition.subOption}
          onChange={subOption => {
            arrayHelpers.replace(
              index,
              changeSubOptionInFormCondition(
                condition,
                subOption.map(v => v.value)
              )
            );
          }}
          options={condition.values ?? []}
        />
      );
  }
};

export type FormValues = {
  name: string;
  description: string;
  targetSystem: TargetSystem | null;
  registerCountry: string;
  lastLoginCountry: string[];
  conditions: FormCondition[];
};

type Props = {
  onSave: (values: FormValues) => void;
  initDisabled: boolean;
  initialValues: FormValues;
  attributes: SegmentsAttribute[];
  isSaving: boolean;
  canSave: boolean;
  categories: SegmentsAttributesResponse["categories"];
  saveText: string;
};

export const SegmentForm: FC<Props> = ({
  initialValues,
  onSave,
  initDisabled,
  canSave,
  attributes,
  categories,
  isSaving,
  saveText,
}) => {
  const { getOptionsByCategory, getByFieldName, getCategories } = useAttributesUtils(attributes, categories);
  const navigate = useNavigation();
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(initDisabled);
  const addConditionClickHandler = (category: AttributesCategory, values: any, setValues: any) => {
    const updated: { conditions: FormCondition[] } = { ...values, conditions: [...values.conditions] };
    updated.conditions.push(makeNoCondition(category));
    setValues({ ...updated });
  };

  const addSegmentSchema = Yup.object().shape({
    name: Yup.string().required(t("errors.segment.requiredName")),
    description: Yup.string().required(t("errors.segment.requiredDescription")),
    targetSystem: Yup.string().required(t("errors.segment.requiredTargetSystem")).nullable(),
    registerCountry: Yup.string().when("targetSystem", {
      is: (targetSystem: TargetSystem) => targetSystem === "IMS",
      then: Yup.string().required(t("errors.segment.requiredRegisterCountry")),
    }),
    lastLoginCountry: Yup.array().when("targetSystem", {
      is: (targetSystem: TargetSystem) => targetSystem === "CMS",
      then: Yup.array()
        .of(Yup.string())
        .min(1, t("errors.segment.requiredLastLoginCountry"))
        .required(t("errors.segment.requiredLastLoginCountry")),
    }),
    conditions: Yup.array()
      .of(
        Yup.object().shape({
          option: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string().required(t("errors.segment.conditions.attributeRequired")),
          }),
          subOption: Yup.array().min(1, t("errors.segment.conditions.valuesRequired")).nullable(),
        })
      )
      .notRequired(),
  });
  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={addSegmentSchema} onSubmit={() => {}}>
      {({ errors, touched, values, setValues, validateForm, setErrors, submitForm }) => (
        <Form>
          <VStack spacing={6} justifyContent="flex-start">
            <Field name="name">
              {({ field }: { field: any }) => (
                <FormControl display="flex" alignItems="center" isInvalid={!!errors.name && touched.name}>
                  <FormLabel w="170px" fontWeight="600">
                    Name
                  </FormLabel>
                  <Box flex={1}>
                    <Input
                      {...field}
                      isDisabled={disabled}
                      maxW="340px"
                      size="sm"
                      borderRadius="10px"
                      boxShadow="md"
                      borderColor="#7a7171"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </Box>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field }: { field: any }) => (
                <FormControl display="flex" alignItems="center" isInvalid={!!errors.description && touched.description}>
                  <FormLabel w="170px" fontWeight="600">
                    Description
                  </FormLabel>
                  <Box flex={1}>
                    <Input
                      {...field}
                      isDisabled={disabled}
                      maxW="340px"
                      size="sm"
                      borderRadius="10px"
                      boxShadow="md"
                      borderColor="#7a7171"
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </Box>
                </FormControl>
              )}
            </Field>
          </VStack>

          <Box mt="60px" mb="30px">
            <Text fontWeight="600" fontSize="18px">
              Match all of the following conditions:
            </Text>
          </Box>

          <Box m="24px">
            <Field name="targetSystem" as="select">
              {({ field, form }: { field: any; form: any }) => {
                return (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    isInvalid={!!errors.targetSystem && touched.targetSystem}
                  >
                    <FormLabel fontWeight="600" w="40%" maxW="400px">
                      {t("form.targetSystem")}
                    </FormLabel>
                    <Box flex={1} maxW="340px" ml="48px">
                      <SelectComponent
                        {...field}
                        isMulti={false}
                        options={getByFieldName(TARGET_SYSTEM)?.values ?? []}
                        isDisabled={disabled}
                        value={{ label: field.value, value: field.value }}
                        onChange={options => {
                          form.setFieldValue(field.name, options[0].value);
                        }}
                      />
                      <FormErrorMessage>{errors.targetSystem}</FormErrorMessage>
                    </Box>
                  </FormControl>
                );
              }}
            </Field>
          </Box>

          {values.targetSystem === "IMS" && (
            <Box m="24px">
              <Field name="registerCountry" as="select">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    isInvalid={!!errors.registerCountry && touched.registerCountry}
                  >
                    <FormLabel fontWeight="600" w="40%" maxW="400px">
                      {t("form.registerCountry")}
                    </FormLabel>
                    <Box flex={1} maxW="340px" ml="48px">
                      <SelectComponent
                        {...field}
                        isMulti={false}
                        options={getByFieldName(REGISTER_COUNTRY).values}
                        isDisabled={disabled}
                        value={field.value}
                        onChange={options => {
                          form.setFieldValue(field.name, options[0].value);
                        }}
                      />
                      <FormErrorMessage>{errors.registerCountry}</FormErrorMessage>
                    </Box>
                  </FormControl>
                )}
              </Field>
            </Box>
          )}

          {values.targetSystem === "CMS" && (
            <Box m="24px">
              <Field name="lastLoginCountry" as="select">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    isInvalid={!!errors.lastLoginCountry && touched.lastLoginCountry}
                  >
                    <FormLabel fontWeight="600" w="40%" maxW="400px">
                      {t("form.lastLoginCountry")}
                    </FormLabel>
                    <Box flex={1} maxW="340px" ml="48px">
                      <SelectComponent
                        {...field}
                        name={field.name}
                        isMulti
                        isDisabled={disabled}
                        options={getByFieldName(LAST_LOGIN_COUNTRY)?.values ?? []}
                        value={field.value}
                        onChange={options => {
                          form.setFieldValue(
                            field.name,
                            options.map(opt => opt.value)
                          );
                        }}
                      />
                      <FormErrorMessage>{errors.lastLoginCountry}</FormErrorMessage>
                    </Box>
                  </FormControl>
                )}
              </Field>
            </Box>
          )}

          {categories.length ? (
            <VStack spacing={8} mb={6} justifyContent="flex-start">
              <FieldArray name="conditions">
                {arrayHelpers =>
                  values.conditions.map((condition, index) => {
                    const category = condition.category;
                    let attr: SegmentsAttribute | null = null;
                    if (!isNoCondition(condition)) {
                      attr = getByFieldName(condition.option.value);
                    }
                    const field = `conditions[${index}].field`;
                    return (
                      <FormControl
                        key={index}
                        isInvalid={
                          !!(Array.isArray(errors.conditions)
                            ? !!errors.conditions[index] && touched.conditions?.[index]
                            : errors.conditions && touched.conditions)
                        }
                      >
                        <HStack spacing={12} alignItems="center" w="100%">
                          <Box pos="relative" w="40%" maxW="400px">
                            <Flex pos="absolute" left="6px" top="-20px" w="calc(100% - 12px)">
                              <Text fontSize="12px" fontWeight="600" color="#999">
                                Select from "{t(`categories.${category}`, getLabel(category))}" category
                              </Text>
                              {condition.option.label && (
                                <Tooltip
                                  label={getByFieldName(condition.option.value)?.description ?? condition.option.label}
                                >
                                  <Text
                                    ml="auto"
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="#999"
                                    textDecor="underline"
                                    display="flex"
                                    alignItems="center"
                                    cursor="pointer"
                                    _hover={{ color: "brand.red" }}
                                  >
                                    <Icon ml="4px" as={BsFillInfoSquareFill} boxSize="16px" />
                                  </Text>
                                </Tooltip>
                              )}
                            </Flex>
                            <SelectComponent
                              isDisabled={disabled}
                              name={field}
                              value={[condition.option.value]}
                              getOptionDescription={option => getByFieldName(option.value)?.description ?? ""}
                              options={values.targetSystem ? getOptionsByCategory(category, values.targetSystem) : []}
                              onChange={options =>
                                arrayHelpers.replace(
                                  index,
                                  changeOptionInFormCondition(condition, getByFieldName, options[0])
                                )
                              }
                            />
                          </Box>
                          <Box w="60%" maxW="1000px">
                            {attr && (
                              <ConditionSubOptionInput
                                condition={condition}
                                attr={attr}
                                arrayHelpers={arrayHelpers}
                                index={index}
                                disabled={disabled}
                              />
                            )}
                          </Box>
                          <Box w="20%" maxW="50px">
                            {!disabled && (
                              <Text as="span" display="flex" alignItems="flex-end" ml="auto">
                                <Icon
                                  as={HiOutlineTrash}
                                  boxSize="16px"
                                  cursor="pointer"
                                  _hover={{ color: "brand.red" }}
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Text>
                            )}
                          </Box>
                        </HStack>
                        <FormErrorMessage>
                          {Array.isArray(errors.conditions) && (errors.conditions[index] as any)?.option?.value}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  })
                }
              </FieldArray>
            </VStack>
          ) : (
            <Flex alignItems="center" justifyContent="center">
              <Spinner />
            </Flex>
          )}
          <Text color="brand.red">{!Array.isArray(errors.conditions) && errors.conditions}</Text>
          {values.targetSystem && (
            <CategoriesButton
              categories={getCategories(values.targetSystem)}
              isDisabled={disabled}
              onClick={category => addConditionClickHandler(category, values, setValues)}
            />
          )}
          <Box w="100%" display="flex" justifyContent="flex-end">
            <Button
              variant="secondary"
              leftIcon={<Icon as={AiOutlineArrowLeft} boxSize="16px" />}
              mr={4}
              onClick={navigate.toSegments}
            >
              Back
            </Button>
            {!disabled ? (
              <Button
                isLoading={isSaving}
                variant="cta"
                disabled={!canSave}
                leftIcon={<Icon as={AiOutlineSave} boxSize="12px" />}
                onClick={async () => {
                  await submitForm();
                  const validatedForm = await validateForm(values);
                  if (Object.keys(validatedForm).length === 0) {
                    onSave(values);
                  }
                }}
              >
                {saveText}
              </Button>
            ) : (
              <Tooltip isDisabled={canSave} label="No rights to edit this segment">
                <Button
                  variant="cta"
                  leftIcon={<Icon as={HiOutlinePencil} boxSize="12px" />}
                  onClick={canSave ? () => setDisabled(false) : () => {}}
                  aria-disabled={!canSave}
                >
                  Edit
                </Button>
              </Tooltip>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
