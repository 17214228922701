import { FC, memo, useCallback, useMemo } from "react";
import { Box, Text, Icon, Table, Thead, Tr, Th, Td, Tbody, Tooltip, Spinner } from "@chakra-ui/react";
import { BsCardList } from "react-icons/bs";
import { AiFillWarning } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import { formatDistance, format, differenceInHours } from "date-fns";
import { Segment } from "types/services/segments.definitions";
import { HiOutlineTrash } from "react-icons/hi";
import { Pagination } from "components/Pagination";
import { useNavigation } from "hooks/useNavigation";
import { useMergedSegmentsSize } from "api/segmentsAllRegions";

const SegmentRow: FC<{
  segment: Segment;
  counts: ReturnType<typeof useMergedSegmentsSize>;
  onDelete: (segment: Segment) => void;
}> = ({ segment, counts, onDelete }) => {
  const duplicateSegment = useCallback((segment: Segment) => navigate.toDuplicateSegment(segment.id), [segment]);

  const segmentCount = counts.sumSizeForSegment(segment.id);
  const navigate = useNavigation();
  return (
    <Tr key={segment.id}>
      <Td>{segment.name}</Td>
      <Td>{segment.description}</Td>
      <Td textAlign="center" _hover={{ cursor: "pointer" }}>
        {counts.isLoading && <Spinner data-testid="size-loading" size="xs" />}
        {!counts.isLoading && segmentCount.isValid && (
          <Tooltip
            label={
              <Box textAlign="right">
                {counts.segmentSizePerRegion(segment.id)?.map(({ name, sizeLabel }) => {
                  return (
                    <p key={name} data-testid={`segment_size_${name}`}>
                      {name}: {sizeLabel}
                    </p>
                  );
                })}
              </Box>
            }
          >
            <Text data-testid="segment_size">{segmentCount.sum}</Text>
          </Tooltip>
        )}
        {!counts.isLoading && !segmentCount.isValid && (
          <Tooltip label="There is a problem with this segment. Contact OBDP administrators or try fixing its definition.">
            <Text as="span">
              <Icon
                as={AiFillWarning}
                boxSize="16px"
                cursor="pointer"
                color="orange.300"
                _hover={{ color: "orange.400" }}
              />
            </Text>
          </Tooltip>
        )}
      </Td>
      <Td>{segment.target_system}</Td>
      <Td>{segment.owner}</Td>
      <Td data-testid="segment_last_update">
        {(() => {
          const last_update = new Date(segment.last_update * 1000);
          const now = new Date();
          if (differenceInHours(now, last_update) < 24) {
            return formatDistance(last_update, now, {
              addSuffix: true,
            });
          } else {
            return format(last_update, "yyyy-MM-dd HH:mm");
          }
        })()}
      </Td>
      <Td>
        <div style={{ display: "flex", position: "relative", alignItems: "center" }}>
          <Tooltip label="View segment details">
            <Text as="span">
              <Icon
                as={BsCardList}
                boxSize="16px"
                mr={4}
                cursor="pointer"
                _hover={{ color: "brand.red" }}
                onClick={() => navigate.toEditSegment(segment.id)}
              />
            </Text>
          </Tooltip>
          <Tooltip placement="bottom" label="Delete segment">
            <Text as="span">
              <Icon
                data-testid={`delete-button-${segment.id}`}
                as={HiOutlineTrash}
                boxSize="16px"
                mr={4}
                cursor="pointer"
                _hover={{ color: "brand.red" }}
                onClick={() => onDelete(segment)}
              />
            </Text>
          </Tooltip>
          <Tooltip placement="bottom" label="Duplicate segment">
            <Text as="span">
              <Icon
                as={BiDuplicate}
                boxSize="16px"
                cursor="pointer"
                _hover={{ color: "brand.red" }}
                onClick={() => duplicateSegment(segment)}
              />
            </Text>
          </Tooltip>
        </div>
      </Td>
    </Tr>
  );
};

const Segments: FC<{ segments: Segment[]; onDelete: (segment: Segment) => void }> = ({ segments, onDelete }) => {
  const segmentIds = useMemo(() => segments.map(segment => segment.id), [segments]);
  const counts = useMergedSegmentsSize(segmentIds);
  return (
    <Table variant="segments">
      <Thead>
        <Tr>
          <Th>Segment name</Th>
          <Th>Description</Th>
          <Th textAlign="center">Segment size</Th>
          <Th>Target system</Th>
          <Th>Author</Th>
          <Th>Last edited</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody pos="relative">
        {segments.map(segment => (
          <SegmentRow key={segment.id} segment={segment} counts={counts} onDelete={onDelete} />
        ))}
      </Tbody>
    </Table>
  );
};

export const TableData: FC<{
  itemOffset: number;
  currentPage: number;
  pageCount: number;
  dataSize: number;
  currentItems: Segment[];
  handleNextPageClick: () => void;
  handlePrevPageClick: () => void;
  onDelete: (segment: Segment) => void;
}> = memo(
  ({
    itemOffset,
    currentPage,
    pageCount,
    dataSize,
    currentItems,
    handleNextPageClick,
    handlePrevPageClick,
    onDelete,
  }) => {
    return (
      <>
        {currentItems.length > 0 && (
          <>
            <Pagination
              itemOffset={itemOffset}
              currentPage={currentPage}
              pageCount={pageCount}
              dataSize={dataSize}
              handlePrevPageClick={handlePrevPageClick}
              handleNextPageClick={handleNextPageClick}
            />
            <Segments onDelete={onDelete} segments={currentItems} />
          </>
        )}
      </>
    );
  }
);
