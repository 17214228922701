import { HStack, Text } from "@chakra-ui/react";
import { Select } from "components/Select";
import { FC, useMemo, useState } from "react";
import { UiSubtype } from "types/services/segments.definitions";
import { NumberInput } from "./NumberInput";

type Value = [string, number];
type Option = { label: string; value: string };

type MultiSelectValueProps = {
  name: string;
  value: Value;
  type: UiSubtype;
  options: Option[];
  isDisabled?: boolean;
  onChange: (attr: Value) => void;
};

const toOption = (internal: Value, options: Option[]): Option => {
  return {
    label: options.find(opt => opt.value === internal[0])?.label ?? internal[0],
    value: internal[0],
  };
};

export const MultiSelectValue: FC<MultiSelectValueProps> = ({
  name,
  type,
  value,
  options,
  isDisabled = false,
  onChange,
}) => {
  const [selectedOption, setSelectOption] = useState<Value>(value);

  const onChangeSiteHandler = (value: Option[]) => {
    const nextSelected = [value[0].value, selectedOption[1] ?? 0] as [string, number];
    setSelectOption(nextSelected);
    onChange(nextSelected);
  };

  const onChangeNumberHandler = (value: number) => {
    const nextSelected = [selectedOption[0], value] as [string, number];
    setSelectOption(nextSelected);
    onChange(nextSelected);
  };

  const label = useMemo(() => {
    switch (type) {
      case "feature":
        return "Used feature";
      case "banner":
        return "Clicked banner";
      case "page":
        return "Visited page";
      default:
        return "";
    }
  }, [type]);

  return (
    <HStack alignItems="center" justifyContent="space-between" spacing={1}>
      {label && (
        <Text fontSize="13px" fontWeight="700" textAlign="center">
          {label}
        </Text>
      )}
      <Select
        name={name}
        value={[selectedOption[0]]}
        options={options}
        onChange={onChangeSiteHandler}
        width="250px"
        isDisabled={isDisabled}
      />
      <Text fontSize="13px" fontWeight="700" textAlign="center">
        at least
      </Text>
      <NumberInput isDisabled={isDisabled} value={selectedOption[1]} min={0} onChange={onChangeNumberHandler} />
      <Text fontSize="13px" fontWeight="700" textAlign="center">
        times
      </Text>
    </HStack>
  );
};
