import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  SegmentsResponse,
  SegmentResponse,
  TargetSystem,
  FormCondition,
  SegmentQuery,
  Segment,
} from "types/services/segments.definitions";
import { useAuthFetch } from "./utils";

export function useGetSegments() {
  const { fetch } = useAuthFetch();
  return useQuery(
    ["segments"],
    (): Promise<SegmentsResponse> =>
      fetch(`${process.env.REACT_APP_CRUD_API_URL}/segments`, {
        method: "GET",
      }).then(
        (segments: Segment[]) =>
          segments.sort(
            (a: Segment, b: Segment) => new Date(b.last_update).valueOf() - new Date(a.last_update).valueOf()
          )
        // TODO - such sorting should be provided by API already
      )
  );
}

export function useGetSegment(id: string) {
  const { fetch } = useAuthFetch();
  return useQuery(
    ["segment", id],
    (): Promise<SegmentResponse> =>
      fetch(`${process.env.REACT_APP_CRUD_API_URL}/segments/${id}`, {
        method: "GET",
      })
  );
}

export const isDuplicateNameError = (err: Error) => {
  return err.message === "DuplicateSegmentNameException";
};

export type SegmentRequest = {
  name: string;
  description: string;
  targetSystem: TargetSystem | null;
  registerCountry: string;
  lastLoginCountry: string[];
  conditions: FormCondition[];
  owner: string;
  query: SegmentQuery;
};
export function useAddSegment() {
  const { fetch } = useAuthFetch();
  return useMutation(
    ["add_segment"],
    (values: SegmentRequest): Promise<SegmentResponse> =>
      fetch(`${process.env.REACT_APP_CRUD_API_URL}/segments`, {
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          target_system: values.targetSystem,
          register_country: values.registerCountry,
          last_login_country: values.lastLoginCountry,
          query: values.query,
          owner: values.owner,
          regions: ["global"],
        }),
      })
  );
}

export function useUpdateSegment(id: string) {
  const { fetch } = useAuthFetch();
  return useMutation(
    ["update_segment", id],
    (values: SegmentRequest): Promise<SegmentResponse> =>
      fetch(`${process.env.REACT_APP_CRUD_API_URL}/segments/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          target_system: values.targetSystem,
          register_country: values.registerCountry,
          last_login_country: values.lastLoginCountry,
          query: values.query,
          owner: values.owner,
          regions: ["global"],
        }),
      })
  );
}

export function useDeleteSegment() {
  const { fetch } = useAuthFetch();
  const queryClient = useQueryClient();
  return useMutation(
    ["delete_segment"],
    (id: string): Promise<SegmentResponse> =>
      fetch(`${process.env.REACT_APP_CRUD_API_URL}/segments/${id}`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["segments"] });
      },
    }
  );
}
