import { Box, Spinner, Text } from "@chakra-ui/react";
import { usePagination } from "hooks/usePagination";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMergedSegmentAttributes } from "api/segmentsAllRegions";
import { AttributesData } from "./AttributesData";
import { HelpAttribute } from "./types";

export const AttributesDataWrapper = () => {
  const [filteredAttributes, setFilteredAttributes] = useState<HelpAttribute[]>([]);
  const { attributes, isLoading, isError } = useMergedSegmentAttributes();
  const { t } = useTranslation();

  const { itemOffset, currentPage, pageCount, currentItems, handleNextPageClick, handlePrevPageClick } =
    usePagination<HelpAttribute>(filteredAttributes);

  useEffect(() => {
    if (attributes) {
      setFilteredAttributes(
        attributes.map(attr => ({
          name: attr.name,
          description: attr.description,
          category: attr.category,
          comment: "",
        }))
      );
    }
  }, [attributes]);

  return (
    <Box pos="relative" minH="200px">
      <AttributesData
        dataSize={filteredAttributes.length}
        itemOffset={itemOffset}
        currentPage={currentPage}
        pageCount={pageCount}
        currentItems={currentItems}
        handleNextPageClick={handleNextPageClick}
        handlePrevPageClick={handlePrevPageClick}
      />
      {isLoading && (
        <Box as="span" display="block" pos="absolute" top="100px" left="50%" transform="translateX(-50%)">
          <Spinner />
        </Box>
      )}
      {!isLoading &&
        (isError ||
          (currentItems.length === 0 && (
            <Box as="span" display="block" pos="absolute" top="75px" left="50%" transform="translateX(-50%)">
              <Text>{t("common.no_items")}</Text>
            </Box>
          )))}
    </Box>
  );
};
