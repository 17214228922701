import { Menu, MenuButton, MenuList, MenuItem, Button, useToast } from "@chakra-ui/react";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { BiUser } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useLogoutMutation } from "api/auth";
import { useEffect } from "react";
import { removeCredentials } from "redux/features/authSlice";
import { useTranslation } from "react-i18next";
import { useNavigation } from "hooks/useNavigation";
import { useNotification } from "hooks/useNotification";

export const ActionsMenu = () => {
  const username = useAppSelector((state: RootState) => state.auth.username);
  const [logoutTrigger, { isError, isSuccess }] = useLogoutMutation();

  const navigate = useNavigation();
  const { showSuccess, showError } = useNotification();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const clickLogoutBtnHandler = () => {
    logoutTrigger();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(removeCredentials());
      showSuccess({ body: t("messages.success.logout.message"), title: t("messages.success.logout.title") });
      navigate.toLogin();
    }
  }, [isSuccess, dispatch, navigate, showSuccess, t]);

  useEffect(() => {
    if (isError) {
      showError({ body: t("messages.error.logout.message"), title: t("messages.error.logout.title") });
    }
  }, [isError, showError, t]);

  return (
    <Menu variant="action">
      <MenuButton variant="clear" as={Button} leftIcon={<BiUser />} rightIcon={<MdOutlineKeyboardArrowDown />}>
        {username}
      </MenuButton>
      <MenuList p="0">
        <MenuItem onClick={clickLogoutBtnHandler}>{t("menu_actions.logout")}</MenuItem>
      </MenuList>
    </Menu>
  );
};
