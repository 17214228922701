import { Flex, Button, Box } from "@chakra-ui/react";
import { CalendarContainer } from "react-datepicker";
import { FC, ReactNode } from "react";

import { DatePickerMode } from "./types";

export const CustomCalendarContainer: FC<{
  className?: string;
  mode: DatePickerMode;
  setMode: (mode: DatePickerMode) => void;
}> = ({ className, children, setMode, mode = DatePickerMode.From }) => {
  return (
    <div>
      <CalendarContainer className={className}>
        <Flex w="100%">
          <Button
            flex="1"
            bg={mode === DatePickerMode.From ? "brand.red" : "white"}
            color={mode === DatePickerMode.From ? "white" : "black"}
            _hover={{}}
            _focus={{}}
            onClick={() => setMode(DatePickerMode.From)}
          >
            From
          </Button>
          <Button
            flex="1"
            bg={mode === DatePickerMode.Until ? "brand.red" : "white"}
            color={mode === DatePickerMode.Until ? "white" : "black"}
            _hover={{}}
            _focus={{}}
            onClick={() => setMode(DatePickerMode.Until)}
          >
            Until
          </Button>
          <Button
            flex="2"
            bg={mode === DatePickerMode.Between ? "brand.red" : "white"}
            color={mode === DatePickerMode.Between ? "white" : "black"}
            _hover={{}}
            _focus={{}}
            onClick={() => setMode(DatePickerMode.Between)}
          >
            Between
          </Button>
        </Flex>
        <Box pos="relative">{children}</Box>
      </CalendarContainer>
    </div>
  );
};
