import { Box } from "@chakra-ui/react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "components/Login";

import { useEffect } from "react";
import { Layout } from "components/Layout";
import { useTrack } from "hooks/useTrack";
import { RootState, useAppSelector } from "store";
import { HelpWrapper } from "modules/Help/HelpWrapper";
import { Help } from "modules/Help/Help";
import { ROUTES } from "config/routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { EditSegment } from "modules/UserSegmentation/pages/EditSegment";
import { SegmentsList } from "modules/UserSegmentation/pages/SegmentsList";
import { AddSegment } from "modules/UserSegmentation/pages/AddSegment";
import { DuplicateSegment } from "modules/UserSegmentation/pages/DuplicateSegment";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const username = useAppSelector((state: RootState) => state.auth.username);
  const { login } = useTrack();
  useEffect(() => login(username), [login, username]);

  return (
    <QueryClientProvider client={queryClient}>
      <Box h="100%" display="flex">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate replace to={ROUTES.SEGMENTS.LIST} />} />
            <Route path={ROUTES.SEGMENTS.LIST} element={<SegmentsList />} />
            <Route path={ROUTES.SEGMENTS.ADD} element={<AddSegment />} />
            <Route path={ROUTES.SEGMENTS.DUPLICATE} element={<DuplicateSegment />} />
            <Route path={ROUTES.SEGMENTS.EDIT} element={<EditSegment />} />
            <Route path={ROUTES.HELP} element={<HelpWrapper />}>
              <Route path={ROUTES.HELP} element={<Help />} />
            </Route>
          </Route>
          <Route path={ROUTES.LOGIN} element={<Login />} />
        </Routes>
      </Box>
    </QueryClientProvider>
  );
};

export default App;
