import { RootState } from "store";
import { SegmentsAttributesResponse } from "types/services/segments.definitions";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { makeHeaders, useAuthFetch } from "./utils";

export const REGION_NAME = {
  "eu-west-1": "Europe(Ireland) AWS",
  "ap-south-1": "India AWS",
  "ap-southeast-1": "Singapore AWS",
  "ap-southeast-2": "Australia AWS",
  "eu-central-1": "Europe AWS",
  "sa-east-1": "South America AWS",
  "us-east-1": "US AWS",
  "me-south-1": "Middle East AWS",
} as const;
export type Region = keyof typeof REGION_NAME;
const regions = (): Region[] => JSON.parse(process.env.REACT_APP_SEGMENTS_REGIONS as string);
const makeBaseUrl = (region: Region) => {
  const env_prefix = process.env.REACT_APP_SEGMENTS_API_PREFIX;
  const prefix = env_prefix !== "null" ? `${env_prefix}.` : "";
  return `https://${prefix}${process.env.REACT_APP_SEGMENTS_API_FUNCTION_NAME}.${region}.${process.env.REACT_APP_SEGMENTS_API_SUFFIX}`;
};
const makeUrl = (region: Region, path: string) => `${makeBaseUrl(region)}${path}`;

export type SegmentSize = number | { exception: string; exception_message: string };
export type SegmentSizeResponse = { counts: Record<string, SegmentSize>; region: Region };
export function useSegmentsSize(segmentIds: string[]) {
  const { fetch } = useAuthFetch();
  const query = (region: Region): Promise<SegmentSizeResponse> =>
    fetch(makeUrl(region, "/segments/counts"), {
      method: "POST",
      body: JSON.stringify({
        segments: segmentIds,
      }),
    }).then(res => ({ ...res, region }));

  return useQuery<Awaited<ReturnType<typeof query>>[]>(["count", ...segmentIds], () => {
    return Promise.allSettled(regions().map(region => query(region))).then(values => {
      return values.filter(val => val.status === "fulfilled").map(val => (val as PromiseFulfilledResult<any>).value);
    });
  });
}

export function useSegmentAttributes() {
  const { fetch } = useAuthFetch();
  const query = (region: Region): Promise<SegmentsAttributesResponse> =>
    fetch(makeUrl(region, "/attributes/values"), {
      method: "GET",
    });
  return useQuery<Awaited<SegmentsAttributesResponse>[]>(["attributes"], () => {
    return Promise.allSettled(regions().map(region => query(region))).then(values => {
      const all = values
        .filter(val => val.status === "fulfilled")
        .map(val => (val as PromiseFulfilledResult<SegmentsAttributesResponse>).value);

      if (all.length === 0) {
        throw new Error("No attributes could be downloaded.");
      }
      return all;
    });
  });
}
