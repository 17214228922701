import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { isProd } from "utils/isProd";

import { persistor, store } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import './i18n';

import "@fontsource/raleway/700.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/400.css";

import "react-datepicker/dist/react-datepicker.css";

declare global {
  interface Window {
    commonVersionIdentifier: string;
    SDK: any;
    sensors: any;
  }
}

window.commonVersionIdentifier = "";
window.SDK.init(process.env.REACT_APP_WEB_BI_SDK, "US", isProd() ? 1 : 0);

const currentLocalStorageVersion = localStorage.getItem("current_localstorage_version");

if (process.env.REACT_APP_CURRENT_LOCAL_STORAGE_VERSION !== currentLocalStorageVersion) {
  localStorage.clear();
  localStorage.setItem(
    "current_localstorage_version",
    `${process.env.REACT_APP_CURRENT_LOCAL_STORAGE_VERSION}`
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
