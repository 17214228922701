import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Link } from "@chakra-ui/react";
import { AttributesDataWrapper } from "./AttributesDataWrapper";

export const Menu = () => (
  <Accordion allowMultiple>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Attributes overview
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <AttributesDataWrapper />
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Manual
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} fontSize="12px">
        <Link
          download
          href="/assets/鸿鹄用户分群系统使用手册-中文第四版 User Segmentation Mgmt (Zh-CN manual)＿20230206＿V4.pdf"
          color="brand.red"
          _hover={{ textDecor: "underline" }}
        >
          鸿鹄用户分群系统使用手册-中文第四版 User Segmentation Mgmt (Zh-CN manual)＿20230206＿V4
        </Link>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
