import { Box, Container } from "@chakra-ui/react";
import { ActionsMenu } from "components/ActionsMenu";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Sidebar } from "components/Sidebar";
import { useReauthenticateInterval } from "hooks/useReauthenticateInterval";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  useReauthenticateInterval();

  return (
    <>
      <Sidebar />
      <Box w="100%" pl="90px" pos="relative">
        <Container pos="relative" pt="80px" px="50px" h="100%" maxW="container.xl">
          <Box pos="absolute" top="40px" right="30px">
            <ActionsMenu />
          </Box>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Container>
      </Box>
    </>
  );
};
