import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Center,
  Text,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { NumberInput } from "components/NumberInput";
import { FC, useCallback, useEffect, useState } from "react";
import { Operation } from "types/services/segments.definitions";
import { ModeSelector } from "./ModeSelector";

import { FilterNumberMode } from "./types";

const toMode = (operation: Operation): FilterNumberMode =>
  operation === Operation.LessThan
    ? FilterNumberMode.Until
    : operation === Operation.GreaterThan
    ? FilterNumberMode.From
    : FilterNumberMode.Between;

const toOperation = (mode: FilterNumberMode): Operation =>
  mode == FilterNumberMode.Until
    ? Operation.LessThan
    : mode === FilterNumberMode.From
    ? Operation.GreaterThan
    : Operation.Between;

export const FilterNumber: FC<{
  initValue?: number[];
  operation: Operation;
  isDisabled?: boolean;
  onChange: (value: number[], operation: Operation) => void;
}> = ({ initValue, operation, isDisabled = false, onChange }) => {
  const [mode, setMode] = useState<FilterNumberMode>(toMode(operation));
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [value, setValue] = useState<number[]>(initValue ?? [0]);

  useEffect(() => {
    onChange(value, toOperation(mode));
  }, [mode, value]);

  return (
    <Flex>
      <Popover placement="top" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button disabled={isDisabled} fontWeight="normal" mr={4} _hover={{}} _focus={{}}>
            <Text>
              {mode === FilterNumberMode.From && "Greater than"}
              {mode === FilterNumberMode.Until && "Less than"}
              {mode === FilterNumberMode.Between && "Between"}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <ModeSelector mode={mode} setMode={setMode} onClose={onClose} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {(mode === FilterNumberMode.From || mode === FilterNumberMode.Until) && (
        <Center>
          <NumberInput isDisabled={isDisabled} value={value[0]} min={0} onChange={value => setValue([value])} />
        </Center>
      )}
      {mode === FilterNumberMode.Between && (
        <Center>
          <NumberInput
            isDisabled={isDisabled}
            value={value[0] ?? 0}
            min={0}
            max={value[1] ?? Infinity}
            onChange={v => setValue([v, value[1]])}
          />
          <Text mx={4} opacity={isDisabled ? 0.4 : 1}>
            and
          </Text>
          <NumberInput
            isDisabled={isDisabled}
            value={value[1] ?? value[0] + 1 ?? 1}
            min={value[0] ?? 0}
            onChange={v => setValue([value[0], v])}
          />
        </Center>
      )}
    </Flex>
  );
};
