import { Flex, Text, IconButton } from "@chakra-ui/react";
import { SEGMENTS_LIST_ITEMS_PER_PAGE } from "consts";
import { FC } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

type PaginationProps = {
  itemOffset: number;
  currentPage: number;
  pageCount: number;
  dataSize: number;
  handlePrevPageClick: () => void;
  handleNextPageClick: () => void;
};

export const Pagination: FC<PaginationProps> = ({
  itemOffset,
  currentPage,
  pageCount,
  dataSize,
  handlePrevPageClick,
  handleNextPageClick,
}) => (
  <Flex justifyContent="flex-end" alignItems="center">
    <Text>
      {`${itemOffset + 1} - ${
        currentPage === pageCount - 1 ? dataSize : itemOffset + SEGMENTS_LIST_ITEMS_PER_PAGE
      } of ${dataSize}`}
    </Text>
    <IconButton
      aria-label="Prev"
      variant="ghost"
      fontSize="14px"
      icon={<FaAngleLeft />}
      disabled={currentPage === 0}
      onClick={handlePrevPageClick}
      _hover={{
        color: "brand.red",
      }}
      _focus={{}}
      _active={{}}
    />
    <IconButton
      aria-label="Next"
      variant="ghost"
      fontSize="14px"
      icon={<FaAngleRight />}
      disabled={currentPage === pageCount - 1}
      onClick={handleNextPageClick}
      _hover={{
        color: "brand.red",
      }}
      _focus={{}}
      _active={{}}
    />
  </Flex>
);
