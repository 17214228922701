import { InputGroup, InputRightElement, Input } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { ChangeEvent, FC, useState } from "react";

type SearchBarProps = {
  onChange: (a: string) => void;
};

export const SearchBar: FC<SearchBarProps> = ({ onChange }) => {
  const [state, setState] = useState("");

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.currentTarget.value);
    onChange(e.currentTarget.value);
  };

  return (
    <InputGroup size="sm">
      <Input
        placeholder="Search by segment name, description, author, target system"
        borderRadius="10px"
        boxShadow="md"
        _focus={{}}
        _active={{}}
        onChange={e => changeHandler(e)}
        value={state}
      />
      <InputRightElement children={<FiSearch color="brand.gray" />} />
    </InputGroup>
  );
};
