import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * TODO - this is last thing keeped in redux and used with redux toolkit
 * remove it and use react-query
 * probably also lets move this state to smth else than redux to remove whole redux depenency
 */

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, expireTime: null, username: "" } as {
    token: null | string;
    expireTime: null | number;
    username: string;
  },
  reducers: {
    setCredentials: (
      state,
      { payload: { token, expireTime } }: PayloadAction<{ token: string; expireTime: number }>
    ) => {
      state.token = token;
      state.expireTime = expireTime;
    },
    removeCredentials: state => {
      state.token = null;
      state.expireTime = null;
      state.username = "";
    },
    setUser: (state, { payload: { username } }: PayloadAction<{ username: string }>) => {
      state.username = username;
    },
  },
});

export const { setCredentials, removeCredentials, setUser } = authSlice.actions;
export default authSlice.reducer;
