export const ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  HELP: "/help",
  SEGMENTS: {
    ADD: "/segments/add",
    DUPLICATE: "/segments/duplicate/:id",
    EDIT: "/segments/:id",
    LIST: "/segments",
  },
};
