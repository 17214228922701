import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Icon,
} from "@chakra-ui/react";
import { FC } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { Segment } from "types/services/segments.definitions";

type ConfirmationDeleteModalProps = {
  isOpen: boolean;
  segment: Segment | null;
  onClose: () => void;
  onDelete: () => void;
  isDeleting: boolean;
};

export const ConfirmationDeleteModal: FC<ConfirmationDeleteModalProps> = ({
  isOpen,
  segment,
  onClose,
  onDelete,
  isDeleting,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton _focus={{}} _active={{}} />
        <ModalBody>
          <Text>
            This operation will delete the segment:{" "}
            <Text as="span" fontWeight="600">
              {segment?.name || ""}
            </Text>
            <br />
            <br />
            <Text as="span" color="brand.red">
              This segment cannot be restored later.
            </Text>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<Icon as={IoClose} boxSize="16px" />}
            bgColor="white"
            color="brand.gray"
            fontWeight="500"
            borderRadius="10px"
            boxShadow="md"
            mr={4}
            _hover={{}}
            _focus={{}}
            _active={{}}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={isDeleting}
            display="flex"
            alignItems="center"
            leftIcon={<Icon as={HiOutlineTrash} boxSize="16px" />}
            ml="auto"
            bgColor="brand.red"
            color="white"
            fontWeight="500"
            borderRadius="10px"
            boxShadow="md"
            lineHeight="16px"
            _hover={{}}
            _focus={{}}
            _active={{}}
            onClick={onDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
